









































































































import { Component, Mixins } from 'vue-property-decorator';
import { Profile, ListingStatus, WebsiteLevel, MapSettings } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { ListingFilter, ListingOrderBy, TestimonialFilter, TestimonialOrderBy } from 'client-website-ts-library/filters';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';

import Listings from '@/components/Listings.vue';
import TestimonialList from '@/components/Testimonials/TestimonialList.vue';
import Loader from '@/components/UI/Loader.vue';

import { AnalyticsEventObject } from 'client-website-ts-library/services/Analytics';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Listings,
    TestimonialList,
    Loader,
    Form,
    TestimonialCycler,
  },
})
export default class AgentProfile extends Mixins(AsyncView) {
  public profile: Profile | null = null;

  public listingFilter: ListingFilter | null = null;

  public currentListingFilter: ListingFilter | null = null;

  public soldListingFilter: ListingFilter | null = null;

  public testimonialFilter: TestimonialFilter | null = null;

  public hasSoldListings = true;

  public hasCurrentListings = true;

  public hasTestimonials = true;

  public mapSettings: MapSettings | null = null;

  public formData: FormConstructorData | null = null;

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.profile === null) return null;

    let about = this.stripHtml(this.profile.AboutAgent);

    if (about.length > 156) {
      about = `${about.substring(0, 156)}...`;
    }

    return {
      title: `${this.profile.FullName} - ${this.profile.Position}`,
      description: about,
      image: this.profile.Photo ? this.profile.Photo.Preview.Url : undefined,
    };
  }

  updateAgent() {
    API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
      this.profile = profile;

      this.$emit('updateView');

      this.currentListingFilter = new ListingFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
        OrderByStatements: [ListingOrderBy.CreateDateDesc],
        PageSize: 6,
      });

      this.formData = new FormConstructorData([
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: this.profile!.Id,
        },
      ]);

      this.soldListingFilter = new ListingFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        Statuses: [ListingStatus.Sold, ListingStatus.Leased],
        OrderByStatements: [ListingOrderBy.LastUpdatedDateDesc],
        PageSize: 6,
      });

      this.testimonialFilter = new TestimonialFilter({
        SearchLevel: WebsiteLevel.Profile,
        SearchGuid: this.profile!.Id,
        OrderByStatements: [TestimonialOrderBy.CreateDateDesc],
      });

      this.mapSettings = new MapSettings({
        Zoom: 20,
        Interactive: false,
        Padding: 80,
        Markers: [
          {
            Title: `${this.profile.Office.FranchiseName} ${this.profile.Office.OfficeName}`,
            Address: `${this.profile.Office.AddressLine1} ${this.profile.Office?.AddressLine2}`,
            Coordinate: this.profile.Office.Coordinate,
          },
        ],
      });
    });
  }

  mounted() {
    this.updateAgent();
  }

  getAnalyticsData(): AnalyticsEventObject | null {
    if (this.profile === null) return null;

    return AnalyticsEventObject.FromProfile(this.profile);
  }
}
